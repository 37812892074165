import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'


import 'video.js/dist/video-js.css'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
import Zh from './assets/languages/zh.json'
import Vm from './assets/languages/vm.json'



const i18n = new VueI18n({

locale: 'vm', // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取

messages: {

'zh': Zh, // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置

'vm': Vm

}

});


Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')






